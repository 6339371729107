import { useForm } from 'react-hook-form';
import { Card, MenuItem, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { toast } from 'react-hot-toast';
import { useEffect, useMemo } from 'react';
import { FormProvider, RHFSelect } from '../../shared/hook-form';
import {
  useGetReminderService,
  useUpdateReminderService,
} from '#/api/userQueries';
import reminderFrequencies from '#/components/pages/User/interruptProcessMenu';
import { ReminderService } from '#/types/user';
import useLocales from '#/hooks/useLocales';
import NonSubscriptionBlock from '#/components/shared/non-subscription-block';
import useAuth from '#/lib/hooks/useAuth';

export default function AccountNotifications() {
  const { user } = useAuth();
  const { data: reminderService } = useGetReminderService();
  const { mutateAsync: updateReminderService } = useUpdateReminderService();

  const { translate } = useLocales();

  const defaultValues = useMemo(
    () => ({
      reminder_frequency: reminderService?.reminder_frequency || '',
    }),
    [reminderService]
  );

  const methods = useForm({
    defaultValues,
  });

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting, isDirty },
  } = methods;

  useEffect(() => {
    if (reminderService) {
      reset(defaultValues);
    }
    // eslint-disable-next-line
  }, [reminderService]);

  const onSubmit = async (data: ReminderService) => {
    // eslint-disable-next-line no-promise-executor-return
    await updateReminderService(data)
      .then(() => {
        toast.success(
          String(translate('toast_notifications.success.reminder_service'))
        );
      })
      .catch(() => {
        toast.error(
          String(translate('toast_notifications.error.reminder_service'))
        );
      });
  };

  const disabled = isSubmitting || !isDirty;

  const renderForm = (
    <>
      <Stack
        direction={{
          xs: 'column',
          sm: 'row',
        }}
        spacing={2}
        alignItems="center"
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="body1">
          {String(translate('global.userSettings.changeReminder'))}
        </Typography>
        <RHFSelect
          name="reminder_frequency"
          label={String(translate('global.userSettings.reminderFrequency'))}
          sx={{
            width: {
              xs: '100%',
              sm: 340,
            },
          }}
        >
          {reminderFrequencies.map(({ value }) => (
            <MenuItem key={value} value={value}>
              {String(
                translate(`global.userSettings.frequencyChoices.${value}`)
              )}
            </MenuItem>
          ))}
        </RHFSelect>
      </Stack>
      <Stack>
        <LoadingButton
          aria-label="save changes"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          disabled={disabled}
          sx={{ ml: 'auto', mt: 2 }}
        >
          <Typography>{String(translate('global.save'))}</Typography>
        </LoadingButton>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 3 }}>
        <Typography variant="subtitle1">
          {String(translate('global.userSettings.reminder'))}
        </Typography>
        {user?.subscription_package === 'starter' && (
          <NonSubscriptionBlock
            message={
              <Stack>
                <Typography
                  variant="subtitle1"
                  textAlign="center"
                  lineHeight={1.1}
                  margin={1}
                >
                  {String(translate('global.disabledService.title'))}
                </Typography>
                <Typography textAlign="center">
                  {String(translate('global.disabledService.description'))}
                </Typography>
              </Stack>
            }
          >
            {renderForm}
          </NonSubscriptionBlock>
        )}
        {user?.subscription_package !== 'starter' && renderForm}
      </Card>
    </FormProvider>
  );
}
