import { Box } from '@mui/material';

interface NonSubscriptionBlockProps {
  children: React.ReactNode;
  message?: React.ReactNode;
}

export default function NonSubscriptionBlock({
  children,
  message,
}: NonSubscriptionBlockProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
      }}
    >
      <Box
        sx={{
          filter: 'blur(5px)',
          '-webkit-filter': 'blur(5px)',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
      >
        {children}
      </Box>
      {message && (
        <Box
          sx={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            paddingY: '1rem',
            transform: 'translate(-50%, -50%)',
            width: '520px',
            paddingX: '1rem',
            alignItems: 'center',
            background: 'rgba( 255, 255, 255, 0.65 )',
            boxShadow: '0 8px 32px 0 rgba( 31, 38, 135, 0.37 )',
            backdropFilter: 'blur( 19px )',
            '-webkit-backdrop-filter': 'blur( 19px )',
            borderRadius: '10px',
            border: '1px solid rgba( 255, 255, 255, 0.18 )',
            gap: 2,
          }}
        >
          {message}
        </Box>
      )}
    </Box>
  );
}
