import { Box, Container, Card, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { pricings } from '#/components/pages/Landing/landingData';
import PricingCard from '#/components/pages/Landing/Cards/PricingCard';
import useLocales from '#/hooks/useLocales';
import useAuth from '#/hooks/useAuth';
import { fDate } from '#/utils/formatTime';

export default function AccountBilling() {
  const [transformBackground, setTransformBackground] = useState(false);
  const theme = useTheme();
  const { translate } = useLocales();
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    setTransformBackground(true);
    if (transformBackground) {
      setTimeout(() => {
        setTransformBackground(false);
      }, 1000);
    }
  }, [transformBackground]);

  const isStarter = user?.subscription_package === 'starter';
  const isStandard = user?.subscription_package === 'advanced';
  const isFree = user?.subscription_package === 'free';
  const isFamily =
    user?.subscription_package === 'family' ||
    user?.subscription_package === 'family_member';

  return (
    <Container maxWidth="lg">
      <Card
        sx={{
          // display: 'flex',
          gap: 10,
          padding: 2,
          background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
          backgroundSize: ' 200% 100%',
          backgroundPosition: ' -100% 0',
          transition: 'background-position 1s ease, transform 0.3s ease',
          ':hover': {
            backgroundPosition: ' 100% 0',
          },
        }}
      >
        <Typography variant="h6" color="#fff">
          {String(translate('global.currentPackage'))}{' '}
          {isStarter && String(translate('global.starterPackage'))}
          {isStandard && String(translate('global.standardPackage'))}
          {isFree && String(translate('global.freePackage'))}
          {isFamily && String(translate('global.familyPack'))}
          {!isStandard &&
            !isStarter &&
            !isFree &&
            !isFamily &&
            String(translate('global.noPackage'))}
        </Typography>
        <Typography color="#fff">
          {String(translate('global.expiresAt'))}
          {': '}
          {fDate(user?.subscription_expires, 'dd. MMM yyyy', 'de')}
        </Typography>
      </Card>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
          marginY: 5,
          justifyContent: 'center',
          gap: 4,
          flexGrow: 1,
        }}
      >
        {pricings
          .filter((pricing) =>
            isAuthenticated
              ? pricing.productId !== 'starter'
              : pricing.productId
          )
          .map((pricing, i) => (
            <PricingCard {...pricing} key={i} />
          ))}
      </Box>
    </Container>
  );
}
